import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import * as S from '../styles'

const Photo = styled.img`
  @media (max-width: 767px) {
    margin-left: 0;
  }
`

const Profile = styled.div`
  margin-bottom: 90px;

  &:last-of-type {
    margin-bottom: 0;

    ${S.Grid} {
      @media (max-width: 767px) {
        flex-direction: column-reverse;
        ${Photo} {
          margin-bottom: 30px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 60px;
  }
`

const PartnerQ = graphql`
  query {
    allContentfulPartnerProfile {
      edges {
        node {
          title
          profileImage {
            file {
              url
            }
          }
          text {
            text
          }
        }
      }
    }
  }
`

const About: React.SFC<any> = () => {
  return (
    <S.Section className="sub">
      <S.Wrapper>
        <StaticQuery
          query={PartnerQ}
          render={data => {
            const partners = data.allContentfulPartnerProfile.edges
            return (
              <>
                {partners.map((p, index) => {
                  const photo = (
                    <S.GridItem
                      width="48%"
                      breakpoints={{
                        768: '100%',
                      }}>
                      <Photo src={p.node.profileImage.file.url} />
                    </S.GridItem>
                  )
                  const info = (
                    <S.GridItem
                      width="48%"
                      breakpoints={{
                        768: '100%',
                      }}>
                      <S.Heading as="h2" className="small">
                        Who We Are
                      </S.Heading>
                      <S.Heading as="h3" className="medium">
                        {p.node.title}
                      </S.Heading>
                      <S.Text className="small">{p.node.text.text}</S.Text>
                    </S.GridItem>
                  )
                  return (
                    <Profile key={index}>
                      <S.Grid>
                        {index % 2 === 0 ? photo : info}
                        {index % 2 === 0 ? info : photo}
                      </S.Grid>
                    </Profile>
                  )
                })}
              </>
            )
          }}
        />
      </S.Wrapper>
    </S.Section>
  )
}

export default About
