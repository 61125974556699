import * as React from 'react'

import * as C from '../components'
import * as S from '../styles'

import Intro from '../story/Intro'
import History from '../story/History'
import About from '../story/About'
import ourStoryBanner from '../assets/our-story-banner.jpg'

const Story: React.SFC<any> = props => {
  return (
    <C.App>
      <S.Section
        className="intro story-intro"
        style={{
          background: `url(${ourStoryBanner}) center / cover no-repeat`,
        }}>
        <S.Wrapper style={{ position: 'relative', zIndex: 1 }}>
          <S.Heading className="large white">Our Story</S.Heading>
        </S.Wrapper>
      </S.Section>
      <Intro />
      <History />
      <About />
      <C.JoinCommunity />
    </C.App>
  )
}

export default Story
