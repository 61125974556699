import * as React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import * as S from '../styles'

const SubSection = styled.div`
  display: flex;
  flex-direction: column;

  ${S.Heading} {
    margin-bottom: 15px;
  }

  ${S.Text} {
    line-height: 1.6em;
    max-width: 960px;
    margin: 0 auto 20px;
    text-align: center;

    p {
      margin-bottom: 20px;
    }
  }

  a {
    margin: 0 auto;
    @media (max-width: 479px) {
      margin: 0;
    }
  }
`

export const query = graphql`
  {
    allContentfulLayoutCopy(filter: { slug: { eq: "story/history" } }) {
      edges {
        node {
          slug
          headline
          subheadline
          copy {
            copy
          }
        }
      }
    }
  }
`

const Intro: React.SFC = () => (
  <StaticQuery
    query={query}
    render={data => {
      const {
        headline,
        subheadline,
        copy,
      } = data.allContentfulLayoutCopy.edges[0].node

      return (
        <S.Section className="sub">
          <S.Wrapper>
            <SubSection>
              <S.Heading as="h2" className="small center">
                {headline}
              </S.Heading>
              <S.Heading as="h3" className="medium center">
                {subheadline}
              </S.Heading>
              <S.Text as="div" className="center">
                <ReactMarkdown source={copy.copy} />
              </S.Text>
              <Link to="/shop">
                <S.Button>Shop our wines</S.Button>
              </Link>
            </SubSection>
          </S.Wrapper>
        </S.Section>
      )
    }}
  />
)

export default Intro
